import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/Layout'
import Search from '../components/Search'
import SEO from '../components/SEO'

import { getSimplifiedPosts } from '../utils/helpers'
import config from '../utils/config'

export default function BlogIndex({ data, ...props }) {
  const posts = data.allMarkdownRemark.edges
  const simplifiedPosts = useMemo(() => getSimplifiedPosts(posts), [posts])

  return (
    <Layout>
      <Helmet title={`Blog | ${config.siteTitle}`} />
      <SEO customDescription="Articles, tutorials, snippets, workshops, notes" />
      <header>
        <div className="container">
          <h1>
            {/* <h1 style={{ backgroundColor: "#fadb6b" }}</h1> */}
            <u> 🎯 Articles overview 👩🏻‍💻  </u>
          </h1>
          <p className="subtitle">
            🚀 🔖  Articles, lessons, tutorials, workshops, snippets, notes.
          </p>
        </div>
      </header>
      <section>
        <div className="container">
          <Search posts={simplifiedPosts} {...props} />

          {/* <section>
            <div>
              <h2>2020</h2>
            </div>
            <div>
              <h2>2019</h2>
            </div>
          </section> */}

        </div>
      </section>
      {/** ====== script bellow Removes DISQUS Free ADS ========*/}
      {/**    
<script>
        document.addEventListener('DOMContentLoaded', function () {
          let disqus = document.getElementById('disqus_thread');

    let remove_ads = setInterval(() => {
          let iframes = document.getElementsByTagName('iframe');

        for (var iframe in iframes) {
            if (typeof iframes[iframe].src === 'undefined') {
                continue;
            }

        if (iframes[iframe].src.match(/(ads-iframe)|(disqusads)/gi)) {
          iframes[iframe].style.display = 'none';
        disqus.style.width = '100%';
            }
        }
    }, 500);
     setTimeout(function () {
        clearInterval(remove_ads);
    }, 5000);
});
</script>
*/}
    </Layout>

  )
}

export const pageQuery = graphql`
  query BlogQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { template: { eq: "post" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug  
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
            image { 
              childImageSharp {
                fixed(width: 150, height: 150) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`
